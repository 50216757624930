module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://shioyakitaro.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/home/vagrant/sandbox/gatsby/my-first-gatsby-site/node_modules/gatsby-remark-highlight-code","id":"9ac5e00d-04d3-528d-bcbb-7d384a724d36","name":"gatsby-remark-highlight-code","version":"3.2.0","modulePath":"/home/vagrant/sandbox/gatsby/my-first-gatsby-site/node_modules/gatsby-remark-highlight-code/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/vagrant/sandbox/gatsby/my-first-gatsby-site","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-28413454-3","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"enableWebVitalsTracking":true,"exclude":[]},
    }]
